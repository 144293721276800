import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://1f09095c11bbe20a8d32b4217f943d6f@o4507879534821376.ingest.us.sentry.io/4507879538753536',
    // debug: true,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/annote\.com\/api/,
      /^https:\/\/annote\.com\//,
      /^https:\/\/[a-z0-9-]+\.supabase\.co/, // Added Supabase API domain pattern
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setTag('environment', process.env.NODE_ENV);
}
