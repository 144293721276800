import { v4 as uuidv4 } from 'uuid';
import { Note, UUID, TypeOfNote } from 'core/types';

export const createNote = (title: string, type: TypeOfNote, value?: string, createdFromId?: UUID): Note => {
  return {
    id: uuidv4(),
    type,
    title,
    value: value || '',
    createdFromId,
    createdAt: new Date().toISOString(),
    backlinks: createdFromId ? [createdFromId] : [],
    links: [],
  } as Note;
};
