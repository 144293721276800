// DraggingContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { UUID } from 'core/types';

interface DraggingContextProps {
  isUserDragging: boolean; // auto on if there is a draggingNoteId
  draggingNoteId: UUID | null;
  setDraggingNoteId: (noteId: UUID | null) => void;
  hoveringDropzoneKey: string | null;
  setHoveringDropzoneKey: React.Dispatch<React.SetStateAction<string | null>>;

  // used for react flow
  draggingConnectionId: UUID | null;
  setDraggingConnectionId: React.Dispatch<React.SetStateAction<UUID | null>>;
  isOverLibrary: boolean;
  setIsOverLibrary: React.Dispatch<React.SetStateAction<boolean>>;
  draggingConnectionType: 'source' | 'target' | null;
  setDraggingConnectionType: React.Dispatch<React.SetStateAction<'source' | 'target' | null>>;
}

const DraggingContext = createContext<DraggingContextProps | undefined>(undefined);

interface DraggingProviderProps {
  children: ReactNode;
}

export const DraggingProvider: React.FC<DraggingProviderProps> = ({ children }) => {
  const [draggingNoteId, setDraggingNoteId] = useState<UUID | null>(null);
  const [hoveringDropzoneKey, setHoveringDropzoneKey] = useState<string | null>(null);
  const [draggingConnectionId, setDraggingConnectionId] = useState<UUID | null>(null);
  const [draggingConnectionType, setDraggingConnectionType] = useState<'source' | 'target' | null>(null);
  const [isOverLibrary, setIsOverLibrary] = useState(false);
  return (
    <DraggingContext.Provider
      value={{
        isUserDragging: !!draggingNoteId,
        draggingNoteId,
        setDraggingNoteId,
        hoveringDropzoneKey,
        setHoveringDropzoneKey,
        draggingConnectionId,
        setDraggingConnectionId,
        isOverLibrary,
        setIsOverLibrary,
        draggingConnectionType,
        setDraggingConnectionType,
      }}
    >
      {children}
    </DraggingContext.Provider>
  );
};

export const useDragging = (): DraggingContextProps => {
  const context = useContext(DraggingContext);
  if (!context) {
    throw new Error('useDragging must be used within a DraggingProvider');
  }
  return context;
};
