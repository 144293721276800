import { InputRule } from 'prosemirror-inputrules';
import { schema } from './schema';
import {
  markdownLinkInputRule,
  strongRule,
  emphasisRule,
  internalLinkRule,
  httpLinkInputRule,
  urlLinkInputRule,
} from './customMarkRules';

export const bulletListRule = new InputRule(/^\s*([-+*])\s$/, (state, match, start, end) => {
  const { tr } = state;
  if (match[1]) {
    const listItem = schema.nodes.list_item.createAndFill();
    const bulletList = schema.nodes.bullet_list.createAndFill(null, listItem ? [listItem] : []);
    if (bulletList) {
      tr.replaceWith(start - 1, end, bulletList);
    }
  }
  return tr;
});

export const orderedListRule = new InputRule(/^\s*(\d+)\.\s$/, (state, match, start, end) => {
  const { tr } = state;
  if (match[1]) {
    const listItem = schema.nodes.list_item.createAndFill();
    const orderedList = schema.nodes.ordered_list.createAndFill({ order: +match[1] }, listItem ? [listItem] : []);
    if (orderedList) {
      tr.replaceWith(start - 1, end, orderedList);
    }
  }
  return tr;
});

export const rules = [
  markdownLinkInputRule,
  internalLinkRule,
  emphasisRule,
  strongRule,
  urlLinkInputRule,
  httpLinkInputRule,
  bulletListRule,
  orderedListRule,
  strongRule,
  emphasisRule,
];
