import dagre from 'dagre';
import { isSource, Note } from 'core/types'; // Assuming this utility function exists
import FlowSurface, { ArrangeFunction } from './FlowSurface';

const dagreArrangeFunction: ArrangeFunction = (nodes, edges, fixedNodeIds = []) => {
  // Identify connected and disconnected nodes
  const connectedNodeIds = new Set(edges.flatMap((edge) => [edge.source, edge.target]));
  const disconnectedNodes = nodes.filter((node) => !connectedNodeIds.has(node.id) && !fixedNodeIds.includes(node.id));
  const connectedNodes = nodes.filter((node) => connectedNodeIds.has(node.id) || fixedNodeIds.includes(node.id));
  // Separate source nodes based on note type
  const disconnectedSourceNodes = disconnectedNodes.filter(
    (node) => node.data.note && isSource(node.data.note as Note),
  );

  // Group disconnected nodes by width
  const nodesByWidth = disconnectedNodes.reduce((acc, node) => {
    if (isSource(node.data.note as Note)) return acc; // sources handled seperatly and at the end
    const width = node.width || 0;
    if (!acc[width]) acc[width] = [];
    acc[width].push(node);
    return acc;
  }, {} as Record<number, typeof nodes>);

  let currentY = 0;
  const padding = 80; // Adjust this value to change the spacing between nodes

  // Arrange disconnected nodes in grids based on their width
  const disconnectedLayoutedNodes = Object.entries(nodesByWidth).flatMap(([width, nodesGroup]) => {
    const nodeWidth = parseInt(width, 10) + padding;
    const maxHeight = Math.max(...nodesGroup.map((node) => node.height || 0)) + padding;
    const gridCols = Math.floor((window.innerWidth - 300) / nodeWidth);

    const layerNodes = nodesGroup.map((node, index) => {
      const col = index % gridCols;
      const row = Math.floor(index / gridCols);
      const nodePosition = {
        x: 300 + col * nodeWidth,
        y: currentY + row * maxHeight,
      };

      return {
        ...node,
        position: nodePosition,
      };
    });

    // Calculate the height of this layer
    const layerHeight = Math.ceil(nodesGroup.length / gridCols) * maxHeight;
    // Update currentY for the next layer
    currentY += layerHeight + padding;

    return layerNodes;
  });

  // Process non-source connected nodes with Dagre
  const g = new dagre.graphlib.Graph();
  g.setDefaultEdgeLabel(() => ({}));
  g.setGraph({
    rankdir: 'RL', //  'RL' or 'LR' for left-to-right layout
    align: 'UL', //  'UL' or 'DL' for horizontal distribution
    nodesep: 100,
    ranksep: 150,
    marginx: 90, // Added horizontal margin
    marginy: 50, // Added vertical margin
  });

  // Add connected nodes and fixed nodes to the graph
  connectedNodes.forEach((node) => {
    if (fixedNodeIds.includes(node.id)) {
      g.setNode(node.id, {
        width: node.width,
        height: node.height,
        x: node.position.x,
        y: node.position.y,
      });
    } else {
      g.setNode(node.id, {
        width: node.width,
        height: node.height,
      });
    }
  });

  // Add edges to the graph
  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  console.log('Running Dagre layout with fixedNodeIds', fixedNodeIds);
  // Run the Dagre layout
  dagre.layout(g);

  // Find the leftmost non-fixed node to set a left boundary for connected nodes
  let minX = Infinity;
  connectedNodes.forEach((node) => {
    if (!fixedNodeIds.includes(node.id)) {
      const nodeWithPosition = g.node(node.id);
      minX = Math.min(minX, nodeWithPosition.x);
    }
  });

  const xOffset = Math.max(300, minX);
  const yOffset = currentY; // Start connected nodes below all disconnected layers

  // Update positions for non-source connected nodes
  const layoutedConnectedNodes = connectedNodes.map((node) => {
    if (fixedNodeIds.includes(node.id)) {
      console.log('returning orig position for fixed node', node.id, node.position);
      return node; // Keep the original position for fixed nodes
    }
    const nodeWithPosition = g.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - minX + xOffset,
        y: nodeWithPosition.y - node.height! / 2 + yOffset,
      },
    };
  });

  // Calculate the height of the connected nodes layer
  const connectedNodesHeight =
    layoutedConnectedNodes.length > 0
      ? Math.max(...layoutedConnectedNodes.map((node) => node.position.y + (node.height || 0))) - yOffset
      : 0; // Default to 0 if there are no connected nodes

  // Arrange source nodes in a grid at the bottom
  const sourceNodeWidth = Math.max(...disconnectedSourceNodes.map((node) => node.width || 0)) + padding;
  const sourceNodeHeight = Math.max(...disconnectedSourceNodes.map((node) => node.height || 0)) + padding;
  const sourceCols = Math.floor((window.innerWidth - 300) / sourceNodeWidth);
  const sourceYOffset = yOffset + connectedNodesHeight + padding;

  console.log('sourceCols', sourceCols, sourceNodeWidth, sourceNodeHeight, sourceYOffset);

  const layoutedSourceNodes = disconnectedSourceNodes.map((node, index) => {
    const col = index % sourceCols;
    const row = Math.floor(index / sourceCols);
    return {
      ...node,
      position: {
        x: 300 + col * sourceNodeWidth,
        y: sourceYOffset + row * sourceNodeHeight,
      },
    };
  });

  // Combine all node layouts
  const allLayoutedNodes = [...disconnectedLayoutedNodes, ...layoutedConnectedNodes, ...layoutedSourceNodes];

  return { nodes: allLayoutedNodes, edges };
};

const FlowDagreSurface = (): React.ReactNode => {
  return <FlowSurface arrangeFunction={dagreArrangeFunction} />;
};

export default FlowDagreSurface;
