import { useContext } from 'react';
import styled from 'styled-components';
import { Source } from 'core/types';
import { text, colors } from 'core/styles';
import { getDomain } from 'core/utils/urls';
import { SearchContext, highlightSearchTerm } from 'src/context/SearchContext';
import { Favicon } from '../../Favicon';

const Wrapper = styled.div`
  padding: 1.54rem 1rem 1.25rem 1.81rem;
  display: flex;
  flex-direction: column;
  gap: 0.71rem;
`;

const Title = styled.span`
  font-size: ${text.size.primary};
  font-style: normal;
  font-weight: ${text.weight.bold};
  line-height: ${text.height.primary};
  color: ${colors.text.header};
  font-family: ${text.family.primary};
  flex: 1;
  padding: 0;
  margin: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:empty::before {
    content: attr(placeholder);
  }
`;

const Link = styled.div`
  color: ${colors.text.subtitle};
  font-family: ${text.family.spartan};
  font-size: ${text.size.small};
  font-weight: ${text.weight.regular};
  font-style: normal;
  text-decoration-line: none;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: left;
  gap: 1rem;
`;

export const SmallSourceCard: React.FC<{ source: Source }> = ({ source }) => {
  const { searchTerm } = useContext(SearchContext);
  const prettyURL = getDomain(source.url);

  // TODO - for now annoteCount is just all source.links

  return (
    <Wrapper>
      <Title>{highlightSearchTerm(source.title, searchTerm)}</Title>
      <InfoRow>
        <Favicon url={source.url} />
        <Link>{prettyURL}</Link>
      </InfoRow>
    </Wrapper>
  );
};
