import React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { colors } from 'core/styles';
import ExtensionBanner from 'src/components/ExtensionBanner';
import { DraggingProvider } from 'src/context/DraggingContext';
import { BrainProvider } from 'src/context/BrainContext';
import { FocusProvider } from 'src/context/FocusContext';
import { SearchProvider } from 'src/context/SearchContext';
import Toolbar from 'src/components/Toolbar';
import { ShownIdsProvider } from 'src/context/ShownContext';
import SurfaceMenu from './Surfaces/components/SurfaceMenu';
import ProfileButton from './Settings/SettingsButton';
import Profile from './Settings/Settings';
import { SvgContainer } from './Surfaces/Tree/_components/Link';
import CopyNotesButton from './Surfaces/components/CopyNotes';

const ForegroundSvgContainer = styled(SvgContainer)`
  z-index: 2010;
  pointer-events: none;
`;

const BackgroundSvgContainer = styled(SvgContainer)`
  z-index: 0;
  pointer-events: none;

  .link-line path {
    stroke-dasharray: 3, 3;
    stroke-width: 2px;
  }
`;

const SurfaceNav = styled.div`
  position: absolute;
  display: flex;
  top: 1.5rem;
  right: 1.5rem;
  gap: 1rem;

  > * {
    flex-shrink: 0; // Prevent children from shrinking
  }
`;

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100vh; /* Full viewport height */
  background-color: ${colors.bg.surface};
`;

const SurfaceWrapper = styled.div`
  position: relative; /* Change from static to relative */
  overflow: auto; /* Scrolling happens in this div */
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex; /* Make SurfaceWrapper a flex container */
  flex-direction: column; /* Align children in a column */
`;

type ThinkAppProps = {
  surface: React.ReactNode;
};

const ThinkApp: React.FC<ThinkAppProps> = ({ surface }) => {
  const [isToolbarExpanded, setToolbarExpandState] = React.useState(true);
  const toggleToolbar = (): void => setToolbarExpandState((prev) => !prev);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const toolbarprops = {
    isToolbarExpanded,
    toggleToolbar,
  };
  const surfaceWrapperRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <ExtensionBanner />
      <Page>
        <DndProvider backend={HTML5Backend}>
          <DraggingProvider>
            <FocusProvider>
              <SearchProvider>
                <BrainProvider>
                  <ShownIdsProvider>
                    <Toolbar {...toolbarprops} />
                    <SurfaceWrapper ref={surfaceWrapperRef} id="surface-wrapper">
                      <BackgroundSvgContainer id="surface-svg-background-portal"></BackgroundSvgContainer>
                      <ForegroundSvgContainer id="surface-svg-foreground-portal"></ForegroundSvgContainer>
                      {surface}
                    </SurfaceWrapper>
                    <SurfaceNav>
                      <CopyNotesButton />
                      <SurfaceMenu />
                      {!profileOpen && <ProfileButton onClick={() => setProfileOpen(true)} />}
                    </SurfaceNav>
                    {profileOpen && <Profile onClose={() => setProfileOpen(false)} />}
                  </ShownIdsProvider>
                </BrainProvider>
              </SearchProvider>
            </FocusProvider>
          </DraggingProvider>
        </DndProvider>
      </Page>
    </>
  );
};

export default ThinkApp;
