import { forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide, SimulationNodeDatum } from 'd3-force';
import { Edge } from '@xyflow/react';
import FlowSurface, { ArrangeFunction } from './FlowSurface';

interface SimNode extends SimulationNodeDatum {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  fx?: number | null;
  fy?: number | null;
}

export const D3ForceArrange: ArrangeFunction = (nodes, edges, fixedNodeIds = []) => {
  if (nodes.length === 0) return { nodes, edges };

  // First, identify source and target nodes
  // const sourceNodes = new Set(edges.map((e) => e.source.toString()));
  const targetNodes = new Set(edges.map((e) => e.target.toString()));

  // Calculate bounds to keep everything within a reasonable area
  const maxDistance = Math.min(window.innerWidth * 0.4, 800); // Limit maximum spread
  const centerX = window.innerWidth / 2;

  const simulationNodes: SimNode[] = nodes.map((n, i) => ({
    ...n,
    // Always reset to initial positions based on window size
    x: targetNodes.has(n.id.toString()) ? window.innerWidth * 0.3 : window.innerWidth * 0.7,
    y: window.innerHeight / 2 + (Math.random() - 0.5) * 100, // Add small vertical variation
    id: n.id,
    index: i,
    width: n.width || 0,
    height: n.height || 0,
    fx: fixedNodeIds.includes(n.id) ? n.position.x : undefined,
    fy: fixedNodeIds.includes(n.id) ? n.position.y : undefined,
  }));

  // Process edges
  const simulationEdges = edges.map((edge) => ({
    ...edge,
    source: edge.source.toString(),
    target: edge.target.toString(),
  }));

  // Add a custom force to encourage sources to stay on the right
  const customXForce = (alpha: number): void => {
    simulationNodes.forEach((node) => {
      if (!fixedNodeIds.includes(node.id)) {
        const bias = targetNodes.has(node.id.toString()) ? -1 : 1;
        // Add position limiting
        const distanceFromCenter = Math.abs(node.x - centerX);
        const scaleFactor = distanceFromCenter > maxDistance ? 0.1 : 1;
        // eslint-disable-next-line no-param-reassign
        node.vx = (node.vx || 0) + bias * alpha * 50 * scaleFactor;
      }
    });
  };

  const simulation = forceSimulation(simulationNodes)
    .force(
      'link',
      forceLink<SimNode, Edge>(simulationEdges)
        .id((d) => d.id)
        .distance(200),
    )
    .force('charge', forceManyBody().strength(-50).distanceMax(200).distanceMin(100))
    .force('center', forceCenter(window.innerWidth / 2, window.innerHeight / 2))
    .force(
      'collision',
      forceCollide()
        .radius((d: SimulationNodeDatum) => {
          const node = d as SimNode;
          return Math.max(node.width, node.height) / 2 + 45;
        })
        .strength(0.4),
    )
    .force('x-bias', customXForce)
    .alpha(1)
    .alphaMin(0.001)
    .alphaDecay(0.0228)
    .velocityDecay(0.4);

  // Run more ticks for better stability
  simulation.tick(200);

  // Stop the simulation
  simulation.stop();

  // Update node positions based on simulation results
  const arrangedNodes = nodes.map((node) => {
    const simNode = simulationNodes.find((n) => n.id === node.id);
    if (fixedNodeIds.includes(node.id)) {
      // For fixed nodes, keep their original position
      return node;
    }
    return {
      ...node,
      position: { x: simNode?.x ?? 0, y: simNode?.y ?? 0 },
    };
  });

  return { nodes: arrangedNodes, edges };
};

const FlowD3ForceSurface: React.FC = () => {
  return <FlowSurface arrangeFunction={D3ForceArrange} edgeType="smooth" />;
};

export default FlowD3ForceSurface;
