import styled from 'styled-components';
import { text, colors } from 'core/styles';
import { Icon, IconOption } from './Icon';

const AnnotesInfo = styled.div`
  color: ${colors.text.description};
  font-family: ${text.family.primary};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;

  display: flex;
  padding: 0.375rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: ${colors.bg.quote.primary};
`;

const NumberOfAnnotes: React.FC<{ annoteCount: number }> = ({ annoteCount }) => (
  <AnnotesInfo>
    <Icon type={IconOption.Pen2} size="1.5rem" /> {annoteCount}
  </AnnotesInfo>
);

export default NumberOfAnnotes;
