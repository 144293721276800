import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ShownIdsContext } from 'src/context/ShownContext';
import { Card } from 'src/components/Cards/Card';
import { LinkPort, BacklinkPort } from 'src/components/Cards/Port';

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center columns horizontally */
  height: 100vh; /* Full viewport height */
  background-size: 10px 10px;
  background-position: 0 0;
  overflow: auto;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  margin: 0 2rem; /* Add some space between columns */
`;

const CardWrapper = styled.div`
  padding: 4rem;
`;

const FocusSurface: React.FC = () => {
  const { shownIds, clear, show, surfaceNoteMap } = useContext(ShownIdsContext);

  // Initialize the portRefMap outside of useMemo
  const portRefMap = useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    Object.values(surfaceNoteMap).forEach((note) => {
      refs[`backlink-${note.id}`] = React.createRef();
      refs[`link-${note.id}`] = React.createRef();
    });
    return refs;
  }, [surfaceNoteMap]);

  const focusedNote = useMemo(() => surfaceNoteMap[shownIds[0]], [surfaceNoteMap, shownIds]);

  const links = useMemo(
    () => focusedNote?.links.map((linkId) => surfaceNoteMap[linkId]).filter((n) => n),
    [focusedNote?.links, surfaceNoteMap],
  );

  const backlinks = useMemo(
    () => focusedNote?.backlinks.map((linkId) => surfaceNoteMap[linkId]).filter((n) => n),
    [focusedNote?.backlinks, surfaceNoteMap],
  );

  const focusOnCard = (cardId: string): void => {
    clear();
    show([cardId]);
  };

  return (
    <Surface id="surface">
      <CardColumn>
        <h2>Links</h2>
        {links?.map((link) => (
          <CardWrapper key={`link-wrapper-${link.id}`} onDoubleClick={() => focusOnCard(link.id)}>
            <Card key={`link-${link.id}`} note={link} />
          </CardWrapper>
        ))}
      </CardColumn>
      <CardColumn>
        <h2>Focused Card</h2>
        {focusedNote && <Card key={focusedNote.id} note={focusedNote} />}
      </CardColumn>
      <CardColumn>
        <h2>Backlinks</h2>
        {backlinks?.map((link) => (
          <CardWrapper key={`backlink-wrapper-${link.id}`} onDoubleClick={() => focusOnCard(link.id)}>
            <LinkPort ref={portRefMap[`link-${link.id}`]} isShown />
            <Card key={`backlink-${link.id}`} note={link} />
            <BacklinkPort ref={portRefMap[`backlink-${link.id}`]} isShown />
          </CardWrapper>
        ))}
      </CardColumn>
    </Surface>
  );
};

export default FocusSurface;
