import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { GOOGLE_CLIENT_ID } from 'src/config/general';
import { useProfileContext } from 'src/context/ProfileContext';
import { useDataContext } from 'src/context/DataContext';
import { isSupabaseDatabase } from 'core/dbs/supabaseDb';

const AuthWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  justify-content: center;
`;

interface CredentialResponse {
  credential: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { profile, fetchProfile } = useProfileContext();
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const { annoteDB } = useDataContext();

  // Callback for when the user signs in with Google
  const handleCredentialResponse = useCallback(
    async (response: CredentialResponse): Promise<void> => {
      if (!annoteDB || !isSupabaseDatabase(annoteDB)) return;
      const idToken = response.credential;
      console.log('Application doing supabase signin');
      const { data, error } = await annoteDB.db.auth.signInWithIdToken({
        provider: 'google',
        token: idToken,
      });
      if (error) {
        console.error('Error during Supabase sign-in', error);
        return;
      }
      if (data) {
        console.log('Callback success. Now get and set session', data);
        await annoteDB.initFromSelf();
        console.log('can we get a session?', await annoteDB.db.auth.getSession());
        console.log('can i get the profile now?', annoteDB.getProfile());
        // We should be authed!  trigger getAndSetSession, and profile should get set
        const prof = await fetchProfile();
        console.log('authed', prof, 'if true you will be redirected soon');
      }
    },
    [annoteDB, fetchProfile],
  );

  useEffect(() => {
    const loadGoogleScript = (): void => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => setIsGoogleScriptLoaded(true);
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      setIsGoogleScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (profile) {
      console.log('session is set - going to redirect soon');
      const redirectTo = localStorage.getItem('redirectTo');
      localStorage.removeItem('redirectTo');
      navigate(redirectTo || '/');
      return;
    }
    // Not yet logged in - Setup Google Sign-In
    if (isGoogleScriptLoaded && window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      console.log('rendering google signin button');
      // Render the Google Sign-In button
      const button = document.getElementById('google-signin-button');
      if (button) {
        window.google.accounts.id.renderButton(button, { theme: 'outline', size: 'large' });
      } else {
        console.error('Google Sign-In button element not found');
      }
    }
  }, [profile, isGoogleScriptLoaded, handleCredentialResponse, navigate]);

  return (
    <AuthWrapper>
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-login_uri={`${window.location.origin}/login`}
        data-auto_select="true"
      ></div>
      <div id="google-signin-button"></div>
    </AuthWrapper>
  );
};

export default Login;
