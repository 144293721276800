// strip the "https://" or "http://" from the beginning of a URL
export const getDomain = (url: string): string | null => {
  if (!url || url.trim() === '') return null;
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.warn('Invalid URL for getDomain', error);
    return null;
  }
};

// Utilize Google's favicon domain service to get the favicon for a given URL
export const getFaviconUrl = (url: string): string => {
  if (!url || url.trim() === '') return '';
  try {
    const parsedUrl = new URL(url);
    return `https://www.google.com/s2/favicons?domain=${parsedUrl.hostname}&sz=16`;
  } catch (error) {
    console.warn('Invalid URL for Favicon', error);
    return '';
  }
};
