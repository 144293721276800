import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from '../context/ProfileContext';

const Logout: React.FC = () => {
  const { logout } = useProfileContext();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/', { replace: true });
  }, [logout, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
