/* eslint-disable @typescript-eslint/no-explicit-any */
export type GenericFunction<T extends any[]> = (...args: T) => void | Promise<void>;

export const debounce = <T extends any[]>(cb: GenericFunction<T>, delay = 500): GenericFunction<T> => {
  let timeout: NodeJS.Timeout;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

// Debounce function that also allows flushing the last call
// Useful when you want to ensure the last call is made before unmounting
export const debounceWithFlush = <T extends any[]>(
  cb: GenericFunction<T>,
  delay = 500,
): [GenericFunction<T>, () => void] => {
  let timeout: NodeJS.Timeout | null = null;
  let lastArgs: T | null = null; // Holds the last arguments provided to the debounced function

  const debouncedFunction = (...args: T): void => {
    lastArgs = args; // Store the latest arguments each time the function is called
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (lastArgs) cb(...lastArgs);
      lastArgs = null; // Clear after calling
    }, delay);
  };

  const flush = (): void => {
    if (timeout) {
      clearTimeout(timeout);
      if (lastArgs) {
        cb(...lastArgs);
        lastArgs = null; // Clear after calling
      }
      timeout = null;
    }
  };

  return [debouncedFunction, flush];
};
