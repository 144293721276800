import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';

const StyledButton = styled.button<{ centerPosition: { x: number; y: number } }>`
  position: absolute;
  top: ${({ centerPosition }) => centerPosition.y}px;
  left: ${({ centerPosition }) => centerPosition.x}px;
  transform: translate(-50%, -50%);
  border: 1px solid ${colors.borders.input.primary};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.text.button};
  box-shadow: 0px 3px 6px rgba(15, 15, 15, 0.1);
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-family: ${text.family.primary};
  font-weight: 500;
  background-color: ${colors.bg.primary};
  cursor: pointer;
  z-index: 1011;
  pointer-events: all;

  &:hover {
    box-shadow: 0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1);
  }
`;

type UnlinkButtonProps = {
  onClick: () => void | Promise<void>;
  centerPosition: { x: number; y: number };
  className?: string;
};

const UnlinkButton: React.FC<UnlinkButtonProps> = ({ onClick, centerPosition, className }) => {
  return (
    <StyledButton onClick={onClick} className={`link-line ${className}`} centerPosition={centerPosition}>
      <Icon type={IconOption.Scissors} size="1.375rem" color={colors.text.button} />
      Unlink
    </StyledButton>
  );
};

export default UnlinkButton;
