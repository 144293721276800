// A Port is the round circle on note cards that link lines connect into it.
// It can be of two kinds: link, or backlink

import React from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';

interface PortProps {
  isShown: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const PortCircle = styled.div<PortProps & { kind: 'link' | 'backlink' }>`
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 100;
  /* display: ${(props) => (props.isShown ? 'block' : 'none')}; */
  opacity: ${(props) => (props.isShown ? 1 : 0)};
  // Set the position of the port based on it's "kind"
  ${(props) => (props.kind === 'backlink' ? 'right: -1rem; top: 1rem;' : 'left: -1rem; bottom: 1rem;')}

  background-color: ${colors.bg.primary};
  border-radius: 50%; // Makes the div a circle
  border: 1px solid ${colors.lines.link};

  box-shadow: 0px 0.5px 1px 0px rgba(15, 15, 15, 0.08);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

export const LinkPort = React.forwardRef<HTMLDivElement, PortProps>((props, ref) => {
  return <PortCircle {...props} ref={ref} kind="link" />;
});

export const BacklinkPort = React.forwardRef<HTMLDivElement, PortProps>((props, ref) => {
  return <PortCircle {...props} ref={ref} kind="backlink" />;
});
