import React from 'react';
import styled from 'styled-components';
import { getFaviconUrl } from 'core/utils/urls';

const DefaultFavicon = styled.div`
  width: 16px;
  height: 16px;
  background-color: #ccc; // You can adjust this color
  border-radius: 50%;
`;

const FaviconImg = styled.img`
  width: 16px;
  height: 16px;
`;

interface FaviconProps {
  src?: string;
  url?: string;
}

// Pass a src if you know the direct link to the favicon or the article url if you want it fetched based on the url
export const Favicon: React.FC<FaviconProps> = ({ src, url }) => {
  const [error, setError] = React.useState(false);

  const faviconSrc = src || getFaviconUrl(url || '');
  if (!faviconSrc || error) {
    return <DefaultFavicon />;
  }

  return (
    <FaviconImg
      src={faviconSrc}
      onError={() => {
        console.log('FAVICON MISSING');
        setError(true);
      }}
      alt="favicon"
    />
  );
};
