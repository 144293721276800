import styled from 'styled-components';
import { colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';

const Wrapper = styled.div`
  padding: 1.375rem;
  cursor: pointer;
  border-radius: 6px;
  background: ${colors.bg.primary};
  box-shadow: ${colors.shadow.surfacenav};
  z-index: 10000;

  color: ${colors.icon.primary};
  &:hover {
    background: ${colors.bg.hover};
    & > svg {
      color: ${colors.icon.hover};
    }
  }
  &:active {
    box-shadow: ${colors.shadow.active};
    transform: translateY(1px);
    & > svg {
      color: ${colors.icon.active};
    }
  }
`;

type SettingsButtonProps = {
  onClick: () => void;
};

const SettingsButton: React.FC<SettingsButtonProps> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon type={IconOption.Settings} size="2.25rem" />
    </Wrapper>
  );
};

export default SettingsButton;
