import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { useDataContext } from 'src/context/DataContext';

const Banner = styled.div`
  width: 100%;
  background-color: ${colors.bg.quote.primary};
  color: ${colors.text.primary};
  text-align: center;
  padding: 10px;
  z-index: 1000;
  font-size: 14px;
  position: absolute;
  width: 200px;
  top: 10px;
  right: 100px;
`;

const Text = styled.div`
  margin: 0;

  a {
    color: ${colors.text.link};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    color: ${colors.text.subtitle};
  }
`;

const ExtensionBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { isUsingExtension } = useDataContext();

  const closeBanner = (): void => {
    setShowBanner(false);
    localStorage.setItem('isBannerClosed', 'true');
  };

  useEffect(() => {
    if (isUsingExtension) {
      closeBanner();
      return undefined;
    }

    const timer = setTimeout(() => {
      const isBannerClosed = localStorage.getItem('isBannerClosed');
      if (isBannerClosed || isUsingExtension) return;
      setShowBanner(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isUsingExtension, closeBanner]);

  if (!showBanner) return <></>;

  return (
    <Banner>
      <Text>
        <p>Install our browser extension for the best experience!</p>
        <p>
          <a
            href="https://chromewebstore.google.com/detail/annote/becaeodideblenfemdnhjgbipehcphia?hl=en-US"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to add
          </a>
        </p>
        <CloseButton onClick={() => closeBanner()}>X</CloseButton>
      </Text>
    </Banner>
  );
};
export default ExtensionBanner;
