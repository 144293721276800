import { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, text } from 'core/styles';
import { Icon, IconOption } from '../Icon';

export type TextInputProps = {
  id: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  iconOption?: IconOption;
  initialValue?: string;
  outsideValue?: string;
  className?: string;
  isFocusedByDefault?: boolean;
  onChange?: (id: string, value: string) => void | Promise<void>;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled.label`
  color: ${colors.icon.primary};
  font-weight: ${text.weight.bold};
  font-family: ${text.family.primary};
  font-size: ${text.size.primary};
  line-height: ${text.height.primary};
`;

const Input = styled.input`
  appearance: none;
  border: none;
  outline: none;
  height: 100%;
  flex: 1;

  color: ${colors.text.primary};
  background: inherit;
  font-family: ${text.family.primary};
  font-size: 14px;
  line-height: 20px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

  &::placeholder {
    color: ${colors.text.header};
  }
`;

const InputGroup = styled.div<{ isFocused: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${(props) => (props.disabled ? colors.bg.hover : colors.bg.primary)};
  outline: 1px solid ${(props) => (props.isFocused ? colors.borders.input.selected : colors.borders.input.primary)};
  ${(props) => (props.disabled ? `outline: 1px solid ${colors.bg.selected};` : '')}
  border-radius: 4px;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  height: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

  &:hover {
    ${(props) => (props.disabled ? '' : `outline: 1px solid ${colors.borders.input.selected};`)}
  }

  svg path {
    fill: ${colors.icon.placeholder};
  }
`;

const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
`;

const TextInput: React.FC<TextInputProps> = ({
  id,
  disabled = false,
  label,
  placeholder,
  iconOption,
  initialValue = '',
  outsideValue,
  className,
  isFocusedByDefault = false,
  onChange,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
    if (!onChange) return;
    onChange(id, newValue);
  };

  const handleInputGroupClick = (): void => {
    if (!inputRef.current || disabled) return;
    setIsFocused(true);
    inputRef.current.focus();
  };

  const handleBlur = (): void => {
    setIsFocused(false);
  };

  const isOutsideControl = typeof outsideValue !== 'undefined';
  const inputValue = isOutsideControl ? outsideValue : value;

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      <InputGroup onClick={handleInputGroupClick} isFocused={isFocused} disabled={disabled}>
        {iconOption && <StyledIcon disabled={disabled} type={iconOption} size="12px" />}
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          autoFocus={isFocusedByDefault}
        />
      </InputGroup>
    </Wrapper>
  );
};

export default TextInput;
