import { IDatabase, DatabaseMessage } from 'core/dbs/types';
import { Note, UUID, Profile, TypeOfNote } from 'core/types';

export class AppMessageDatabase implements IDatabase {
  private messageId = 0;

  constructor() {
    console.log('AppMessageDb: Initializing');
    this.sendMessage = this.sendMessage.bind(this);
    this.searchNotes = this.searchNotes.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.getNotesByUrl = this.getNotesByUrl.bind(this);
    this.getNoteById = this.getNoteById.bind(this);
    this.getNoteByTitle = this.getNoteByTitle.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.deleteNotes = this.deleteNotes.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  private handleMessage(event: MessageEvent): void {
    if (event.source !== window) return;
    if (event.data.type === 'ANNOTE_LISTENER_READY') {
      console.log('AppMessageDb: Content script listener is ready');
      // } else {
      //   console.log('AppMessageDb: Received message', event.data);
    }
  }

  private sendMessage<T>(message: DatabaseMessage): Promise<T> {
    return new Promise((resolve, reject) => {
      this.messageId += 1;
      const { messageId } = this;

      const listener = (event: MessageEvent): void => {
        // console.log('AM - received message', event.data);
        if (event.source !== window) return;
        if (event.data.messageType !== 'EXT_RESPONSE') return;
        if (event.data.messageId === messageId) {
          window.removeEventListener('message', listener);
          if (event.data.error) {
            console.log('AM - received error', event.data.error);
            reject(new Error(event.data.error));
          } else {
            // console.log('AM - received result', event.data.result);
            resolve(event.data.result);
          }
        }
      };

      window.addEventListener('message', listener);
      window.postMessage({ messageId, messageType: 'APP_MESSAGE', ...message }, '*');
    });
  }

  async getNotes(ids?: UUID[]): Promise<Note[]> {
    return this.sendMessage<Note[]>({ action: 'getNotes', ids });
  }

  async getNotesByUrl(url: string): Promise<{ notes: Note[]; isBlocked: boolean }> {
    return this.sendMessage<{ notes: Note[]; isBlocked: boolean }>({ action: 'getNotesByUrl', url });
  }

  async getNoteById(id: UUID): Promise<Note | null> {
    return this.sendMessage<Note | null>({ action: 'getNoteById', id });
  }

  async getNoteByTitle(title: string): Promise<Note | null> {
    return this.sendMessage<Note | null>({ action: 'getNoteByTitle', title });
  }

  async searchNotes(query: string, types?: TypeOfNote[], limit: number = 10): Promise<Note[]> {
    return this.sendMessage<Note[]>({ action: 'searchNotes', query, types, limit });
  }

  async updateNotes(notes: Note[]): Promise<void> {
    return this.sendMessage<void>({ action: 'updateNotes', notes });
  }

  async deleteNotes(notes: Note[]): Promise<void> {
    if (!notes || !Array.isArray(notes) || notes.length === 0) {
      throw new Error('Invalid notes array provided for deletion');
    }
    return this.sendMessage<void>({ action: 'deleteNotes', notes });
  }

  async getProfile(): Promise<Profile> {
    return this.sendMessage<Profile>({ action: 'getProfile' });
  }

  async updateProfile(profile: Profile): Promise<void> {
    return this.sendMessage<void>({ action: 'updateProfile', profile });
  }
}

export default AppMessageDatabase;
