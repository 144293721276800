/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ProfileProvider } from './context/ProfileContext';
import { DataProvider } from './context/DataContext';
import './index.css';
import './helpers/initSentry';

// VS Code has some issue with this import - it works fine though
// eslint-disable-next-line import/no-unresolved
if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires, import/no-unresolved
  import('./registerPWA').then(({ registerSW }) => {
    const updateSW = registerSW({
      onNeedRefresh() {
        console.log('refreshing');
        updateSW();
      },
      onOfflineReady() {
        console.log('App is ready to work offline');
      },
    });
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <DataProvider>
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </DataProvider>
  </React.StrictMode>,
);
