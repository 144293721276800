import styled from 'styled-components';
import { Takeaway } from 'core/types';
import { colors, text } from 'core/styles';
import { TITLE_PLACEHOLDER } from './Takeaway';

const WholeCardWrapper = styled.div<{ isWholeCard?: boolean; isOnBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.isWholeCard ? 'width: 100%;' : '')};
  ${(props) => (props.isWholeCard ? 'height: 100%;' : '')};
  justify-content: ${(props) => (props.isOnBottom ? 'flex-end' : 'flex-start')};
  position: relative;
  pointer-events: none;
  user-select: none;

  box-shadow: 0px 1px 2px 0px rgba(15, 15, 15, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #f5f5f5;
`;

const Wrapper = styled.div<{ hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.hasTitle
      ? `border-bottom: 1px solid ${colors.lines.indexRed};`
      : `border-top: 1px solid ${colors.lines.indexRed};  &Title { font-weight: 400 !important; }`};
  padding: 2rem 1.5rem 1rem;

  & span {
    ${(props) => (props.hasTitle ? '' : `font-weight: ${text.weight.regular};`)};
  }
`;

const Title = styled.span<{ placeholder: string }>`
  font-family: ${text.family.primary};
  font-weight: ${text.weight.medium};
  font-size: ${text.size.primary};
  line-height: ${text.height.primary};
  color: ${colors.text.header};
  font-family: ${text.family.primary};
  flex: 1;
  padding: 0;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:empty::before {
    content: attr(placeholder);
    font-weight: ${text.weight.medium};
    color: ${colors.text.placeholder};
  }
`;

export const SmallTakeawayCard: React.FC<{ takeaway: Takeaway }> = ({ takeaway }) => {
  const title = takeaway.title || takeaway.value; // .replace(/\u200B/g, '').trim();
  const hasTitle = takeaway.title.length > 0;

  return (
    <WholeCardWrapper>
      <Wrapper hasTitle={hasTitle}>
        <Title placeholder={TITLE_PLACEHOLDER}>{title}</Title>
      </Wrapper>
    </WholeCardWrapper>
  );
};
