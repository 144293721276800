import React, { useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled, { css } from 'styled-components';
import { useDragging } from 'src/context/DraggingContext';
import { colors } from 'core/styles';
import { UUID } from 'core/types';

const HandleWrapper = styled.div<{ position: Position; isHidden: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 2000;
  ${({ position }) =>
    position === Position.Left &&
    css`
      left: -20px;
      bottom: 18px;
    `}
  ${({ position }) =>
    position === Position.Right &&
    css`
      right: -20px;
      top: 18px;
    `}
`;
const RegularHandleSVG = (): React.ReactElement => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="15.5" height="15.5" rx="7.75" fill="#F5F5F5" />
    <rect x="1.25" y="1.25" width="15.5" height="15.5" rx="7.75" stroke="#878683" strokeWidth="1.5" />
    <path d="M10.4302 4.99951L6.5 9.06933L10.5698 12.9995" stroke="#878683" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);

const HoverHandleSVG = (): React.ReactElement => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="33.5" height="33.5" rx="16.75" fill="#5D6DD9" />
    <rect x="1.25" y="1.25" width="33.5" height="33.5" rx="16.75" stroke="#5D6DD9" strokeWidth="1.5" />
    <path d="M18 12.666V23.3327" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.667 18H23.3337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const StyledFullCardHandle = styled(Handle)`
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.1;
    border: 1px solid ${colors.lines.selected};
    background: ${colors.lines.selected};
  }

  // Position it on top of the node
  z-index: 1000;

  // Remove default React Flow handle styles
  &.react-flow__handle-left,
  &.react-flow__handle-right,
  &.react-flow__handle-top,
  &.react-flow__handle-bottom {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const StyledHandle = styled(Handle)<{ position: Position }>`
  width: 18px;
  height: 18px;
  border: none;
  background: transparent;
  top: 8px;
  left: ${({ position }) => (position === Position.Right ? '-8px' : '8px')};
  z-index: 12000;

  &.react-flow__handle {
    width: 100%;
    height: 100%;
    // No clue why this is needed, but it is
  }
`;

const SVGContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &.react-flow__handle {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface CustomHandleProps {
  type: 'source' | 'target';
  position: Position;
  id: string;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const CustomHandle: React.FC<CustomHandleProps> = ({ type, position, id, isHovered, onMouseEnter, onMouseLeave }) => {
  const { draggingConnectionId, draggingConnectionType } = useDragging();
  const noteId = id.split('-')[1];
  const isHidden = Boolean(
    draggingConnectionId !== null && draggingConnectionId !== noteId! && type === draggingConnectionType,
  );
  return (
    <HandleWrapper position={position} isHidden={isHidden} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledHandle type={type} position={position} id={id} />
      <SVGContainer className="nodrag">
        {isHovered && <HoverHandleSVG />}
        {!isHovered && <RegularHandleSVG />}
      </SVGContainer>
    </HandleWrapper>
  );
};

const FullCardHandle: React.FC<{ id: string; onMouseEnter: () => void; onMouseLeave: () => void }> = ({
  id,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { draggingConnectionId, draggingConnectionType } = useDragging();
  if (!draggingConnectionId || draggingConnectionId === id) return <></>;

  // we need the card handle to be the opposite of the dragging connection
  const type = draggingConnectionType === 'source' ? 'target' : 'source';
  return (
    <StyledFullCardHandle
      type={type}
      position={Position.Left}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

// Wrap your card in this and it'll add the link and backlink and full card handle for you
export const CardHandleWrapper: React.FC<{ children: React.ReactNode; noteId: UUID }> = ({ children, noteId }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (): void => setIsHovered(true);
  const handleMouseLeave = (): void => setIsHovered(false);

  return (
    <>
      {/* backlink handl */}
      <CustomHandle
        type="target"
        position={Position.Right}
        id={`backlink-${noteId}`}
        isHovered={isHovered}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {children}
      <FullCardHandle id={noteId} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {/* link handle */}
      <CustomHandle
        type="source"
        position={Position.Left}
        id={`link-${noteId}`}
        isHovered={isHovered}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};
