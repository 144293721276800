import styled from 'styled-components';
import { colors } from 'core/styles';

const Wrapper = styled.div`
  width: 8.625rem;
  height: 5.75rem;
  position: relative;
  cursor: pointer;

  .cardButton {
    position: absolute;
    top: 50%;
    left: -30px;
    width: 69px; /* Same width as the SVG rectangle */
    height: 46px; /* Same height as the SVG rectangle */
    background-color: #fff; /* White background for the rectangle */
    transform: rotate(-19deg); /* Center and rotate the rectangle */
    transition: transform 0.4s ease-in-out;
    z-index: 1;
    filter: drop-shadow(0px 9px 24px rgba(15, 15, 15, 0.2)) drop-shadow(0px 3px 6px rgba(15, 15, 15, 0.1));
  }

  .redLine {
    position: absolute;
    width: 69px;
    height: 1px; /* Line thickness */
    background-color: ${colors.lines.indexRed}; /* Red color from your theme */
    top: 23%; /* Adjusted to place it higher on the card */
  }

  &:hover {
    .cardButton {
      transform: translate(3rem, 0) rotate(-19deg); /* Move the rectangle to the right */
    }
  }
`;

type NewCardButtonProps = {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
};

const NewCardButton: React.FC<NewCardButtonProps> = ({ className, onClick, onMouseEnter, onMouseLeave }) => {
  const handleOnClick = (): void => {
    if (!onClick) return;
    onClick();
  };

  const handleOnMouseEnter = (): void => {
    if (!onMouseEnter) return;
    onMouseEnter();
  };

  const handleOnMouseLeave = (): void => {
    if (!onMouseLeave) return;
    onMouseLeave();
  };

  return (
    <Wrapper
      className={className}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="cardButton">
        <div className="redLine"></div>
      </div>
    </Wrapper>
  );
};

export default NewCardButton;
