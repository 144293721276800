import { Position } from 'core/types';

// Helper function to get the center position of an element
export const getCenter = (el: HTMLElement, offsetX: number, surface: HTMLElement): Position => {
  const rect = el.getBoundingClientRect();
  // get the scroll position of the surface
  const scrollLeft = surface?.scrollLeft || 0;
  const scrollTop = surface?.scrollTop || 0;
  return {
    x: offsetX + rect.left + rect.width / 2 + scrollLeft,
    y: rect.top + rect.height / 2 + scrollTop,
  };
};

export interface PortRefMap {
  [key: string]: React.RefObject<HTMLDivElement>;
}
