// TODO - this just keeps the focusedNoteId state - it can likely be merged into ShownContext if simpler
// Centralized context to manage the focused note in the application.
// Requires Cards to have a className = "card" to work and an id = {card-noteId}
// This won't actually set a focus - it just keeps track of what the user has focused on
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { UUID } from 'core/types';

interface FocusContextType {
  focusedNoteId: UUID | null;
  focusedTarget: HTMLElement | null;
  setFocus: (id: UUID | null, target?: HTMLElement) => void;
}

export const FocusContext = createContext<FocusContextType>({
  focusedNoteId: null,
  setFocus: () => {},
  focusedTarget: null,
});

export const FocusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [focusedNoteId, _setFocusedNoteId] = useState<string | null>(null);
  const [focusedTarget, _setFocusedTarget] = useState<HTMLElement | null>(null);

  const setFocus = useCallback(
    (id: UUID | null, target?: HTMLElement) => {
      if (id === focusedNoteId) return;
      _setFocusedNoteId(id);
      _setFocusedTarget(target || null);
    },
    [focusedNoteId],
  );

  const handleFocus = useCallback((event: FocusEvent) => {
    const target = event.target as HTMLElement;
    const card = target.closest('.card') as HTMLElement | null;
    // if the card is not found, set the focusedNoteId to null
    const noteId = (card?.getAttribute('id')?.replace('card-', '') as UUID) || null;
    setFocus(noteId, target || null);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleFocus);
    document.addEventListener('focusin', handleFocus);

    return () => {
      document.removeEventListener('click', handleFocus);
      document.removeEventListener('focusin', handleFocus);
    };
  }, [handleFocus]);

  return <FocusContext.Provider value={{ focusedNoteId, focusedTarget, setFocus }}>{children}</FocusContext.Provider>;
};
