import { InputRule } from 'prosemirror-inputrules';
import { TextSelection } from 'prosemirror-state';
import { MarkType } from 'prosemirror-model';
import { schema } from './schema';

// Abstract function to create input rules for marks
const markInputRule = (
  regexp: RegExp,
  markType: MarkType,
  getAttrs?: (match: string[]) => Record<string, string | boolean | number>,
): InputRule => {
  return new InputRule(regexp, (state, match, start, end) => {
    console.log('matching rule', match);
    const { tr } = state;
    const leadingSpace = match[1];
    const text = match[3];
    const trailingChar = match[match.length - 1];
    const attrs = getAttrs ? getAttrs(match) : {};

    if (text) {
      const startOffset = start + (leadingSpace ? leadingSpace.length : 0);
      // Delete the whole match
      tr.delete(start, end);
      // Insert leading space if any
      if (leadingSpace) {
        tr.insertText(leadingSpace, start);
      }
      // Insert the text without leading/trailing spaces
      tr.insertText(text, startOffset);
      // Add the mark to the text
      tr.addMark(startOffset, startOffset + text.length, markType.create(attrs));
      if (trailingChar) {
        tr.insertText(trailingChar, startOffset + text.length);
        tr.removeMark(startOffset + text.length, startOffset + text.length + trailingChar.length, markType);
      }
      // Set the selection to after the inserted text
      tr.setSelection(
        TextSelection.create(tr.doc, startOffset + text.length + (trailingChar ? trailingChar.length : 0)),
      );
    }
    return tr;
  });
};

// Define the input rules using the abstracted function
export const emphasisRule = markInputRule(/(^|\s)(\*|_)([^*_]+)\2(\s|[.,!?])/, schema.marks.em);
export const strongRule = markInputRule(/(^|\s)(\*\*|__)([^*_]+)\2(\s|[.,!?])/, schema.marks.strong);

// Create the link input rule
export const markdownLinkInputRule = markInputRule(
  /(^|\s)(\[)(.+?)\]\(([^\s]+\.[a-zA-Z]{2,5})\)(\s|[.,!?])/,
  schema.marks.link,
  (match) => ({ title: match[3], href: match[4] }),
);

export const internalLinkRule = markInputRule(
  /(^|\s)(\[)(.+?)\]\[([0-9a-fA-F-]+)\](\s|[.,!?])/,
  schema.marks.link,
  (match) => ({ title: match[3], href: match[4], internal: true }),
);

export const httpLinkInputRule = markInputRule(
  /(^|\s)()(http[s]?:\/\/[^\s]+)(\s|[,!?])$/,
  schema.marks.link,
  (match) => ({
    href: match[3],
    title: match[3],
  }),
);

export const urlLinkInputRule = markInputRule(
  /(^|\s)()([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\s|[,!?])$/,
  schema.marks.link,
  (match) => ({
    href: `http://${match[2]}`,
    title: match[2],
  }),
);
