// Lines are the lower level SVG drawing handling of Links

import React from 'react';
import styled from 'styled-components';
import { LinkKind } from 'core/types';
import { colors } from 'core/styles';

export type Position = { x: number; y: number };

const rad = 10; // Radius of the curve and ports

interface BaseLineProps {
  key?: string;
  fromPos: Position;
  toPos: Position;
  hoverHeight: number;
  inFront: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

export interface LineProps extends BaseLineProps {
  kind: LinkKind;
}

const StyledPath = styled.path`
  stroke-width: 1;
  fill: none; // Ensure the path does not get filled
  pointer-events: none;
`;

const HoverPath = styled(StyledPath)`
  stroke-width: 11;
  stroke: 'transparent';
  pointer-events: auto;
`;

const Circle = styled.circle`
  fill: ${colors.text.link};
`;

export const TreeLine: React.FC<BaseLineProps> = ({
  fromPos,
  toPos,
  onClick,
  inFront,
  onMouseEnter,
  onMouseLeave,
  hoverHeight,
}) => {
  const midX = (fromPos.x + toPos.x) / 2;
  // Construct the path data
  const pathData = `
      M ${fromPos.x},${fromPos.y}
      L ${midX + rad},${fromPos.y}
      A ${rad},${rad} 0 0 1 ${midX},${fromPos.y - rad}
      L ${midX},${toPos.y + rad}
      A ${rad},${rad} 0 0 0 ${midX - rad},${toPos.y}
      L ${toPos.x},${toPos.y}
    `;

  const hoverPath = `    
    M ${fromPos.x},${fromPos.y}
    L ${midX + rad},${fromPos.y}
    A ${rad},${rad} 0 0 1 ${midX},${fromPos.y - rad}
    L ${midX},${fromPos.y - hoverHeight} 
  `;

  return (
    <g className="link-line">
      <StyledPath d={pathData} stroke={inFront ? colors.text.link : colors.lines.link} />
      <HoverPath
        d={hoverPath}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        stroke="transparent"
      />
      {inFront && (
        <>
          <Circle cx={toPos.x} cy={toPos.y} r={rad} />
          <Circle cx={fromPos.x} cy={fromPos.y} r={rad} />
        </>
      )}
    </g>
  );
};

export const GraphLine: React.FC<BaseLineProps> = ({
  fromPos,
  toPos,
  onClick,
  inFront,
  onMouseEnter,
  onMouseLeave,
}) => {
  const midX = (fromPos.x + toPos.x) / 2;
  const midY = (fromPos.y + toPos.y) / 2;
  const dx = toPos.x - fromPos.x;
  const dy = toPos.y - fromPos.y;

  // Adjust the control point by shifting it perpendicularly to the line segment
  const controlX = midX - dy * 0.1; // Adjust the 0.1 factor to increase or decrease curvature
  const controlY = midY + dx * 0.1;

  const pathData = `M ${fromPos.x},${fromPos.y} Q ${controlX},${controlY} ${toPos.x},${toPos.y}`;

  return (
    <g className="link-line">
      <StyledPath d={pathData} stroke={inFront ? colors.text.link : colors.lines.link} />
      <HoverPath
        d={pathData}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        stroke="transparent"
      />
      {inFront && (
        <>
          <Circle cx={toPos.x} cy={toPos.y} r={rad} />
          <Circle cx={fromPos.x} cy={fromPos.y} r={rad} />
        </>
      )}
    </g>
  );
};

export const Line: React.FC<LineProps> = ({ kind, ...props }) => {
  if (!props.fromPos || !props.toPos || !props.hoverHeight) {
    return null;
  }
  if (kind === 'tree') {
    return <TreeLine {...props} />;
  }
  return <GraphLine {...props} />;
};
