import { getFaviconUrl, getDomain } from '../../utils/urls';
import { text } from '../../styles';
import { colors } from '../../styles/colors';
import { createNote } from '../../utils/notes';
import { Note, SearchResults, SearchNote, isSearchNote } from '../../types';
import { getDB } from './db';

let NAMESPACE: string;

if (typeof __TARGET__ !== 'undefined') {
  NAMESPACE = __TARGET__ === 'CHROME_EXTENSION' ? 'annote-ext' : 'annote-web';
} else {
  NAMESPACE = 'annote-web'; // default for tests
}

// Define the custom element tag
const SUGGEST_MENU_TAG = `${NAMESPACE}-suggest-menu`;

class SuggestMenu extends HTMLElement {
  suggestions: SearchResults;

  selectedIndex: number;

  query!: string;

  note!: Note;

  recentlyClicked: boolean;

  selectCallback!: (note: Note | SearchNote) => string;

  constructor() {
    super();
    this.suggestions = [];
    this.selectedIndex = -1;
    this.recentlyClicked = false;
    this.hide = this.hide.bind(this);
  }

  connectedCallback(): void {
    this.innerHTML = `
    <style>
        .annote-suggest-menu-wrapper {
           font-family: ${text.family.primary};
            position: absolute;
            border-radius: 6px;
            box-shadow: 0px 3px 6px 0px rgba(15, 15, 15, 0.10), 0px 5px 14px 0px rgba(15, 15, 15, 0.20);
            width: 300px;
            flex: 1;
            background-color: ${colors.bg.primary};
            padding: 0px;
            z-index: 1010;
        }
        .annote-suggest-menu-wrapper #menu-container {
            position: relative;
            display: block;
            // margin-top: 30px;
        }
        .annote-suggest-menu-wrapper .small-note {
            position: relative;
            padding: 16px 12px 8px 12px;
            cursor: pointer;
            font-size: 14px;
            background-color: ${colors.bg.primary};
            // TODO - silvia wants them all to have a box-shadow as well!
            // box-shadow:   0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(15, 15, 15, 0.16);
                white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;

        }
        
        .annote-suggest-menu-wrapper .suggestion-active {
            background-color: ${colors.bg.selected};
        }
        .annote-suggest-menu-wrapper .small-note:hover {
            background-color: ${colors.bg.hover};
        }
        .annote-suggest-menu-wrapper .small-note img.favicon {
          width: 16px;
          height: 16px;
        }
        .annote-suggest-menu-wrapper .small-note.takeaway {
          font-weight: ${text.weight.bold};
          font-size: ${text.size.primary};
          line-height: ${text.height.primary};
          flex: 1;
          border-bottom: 1px solid ${colors.lines.indexRed};
        }
        .annote-suggest-menu-wrapper .small-note.source {
          font-size: ${text.size.primary};
          font-style: normal;
          font-weight: ${text.weight.bold};
          line-height: ${text.height.primary};
          color: ${colors.text.header};
          font-family: ${text.family.primary};
          flex: 1;
        }
        .annote-suggest-menu-wrapper .source-info-row {
            display: flex;
            padding-top: 8px;
        }
        .annote-suggest-menu-wrapper .source-info-row .domain {
          padding-left: 6px;
          color: ${colors.text.subtitle};
          font-family: ${text.family.spartan};
          font-size: ${text.size.small};
          font-weight: ${text.weight.regular};
          font-style: normal;
          text-decoration-line: none;
        }
        .annote-suggest-menu-wrapper .spacer {
          opacity: .5;
          height: 2px;
          background: linear-gradient(0deg, rgba(173, 173, 173, 0.60) 0%, rgba(217, 217, 217, 0.00) 98.96%);
        }
        .annote-suggest-menu-wrapper .subtitle {
          font-size: 12px;
          color: ${colors.text.subtitle};
          font-weight: ${text.weight.medium};
          padding: 16px;
          padding-bottom: 8px;
        }
        .annote-suggest-menu-wrapper .top-spacer {
          opacity: .3;
        }
    </style>
    <div class='annote-suggest-menu-wrapper'>
      <div class='subtitle'>Link to takeaway or source</div>
      <div class="spacer top-spacer"></div>
      <div id="menu-container"></div>
    </div>
`;
    this.hide();
    this.style.position = 'absolute';
    // Handle click events on the menu
    document.addEventListener('mousedown', (e) => {
      if (!this.contains(e.target as Node)) {
        // They clicked outside the menu, hide it
        this.hide();
        return;
      }
      const index = (e.target as HTMLElement).getAttribute('data-index');
      if (!index) return;

      // Stop blurs from happening - they should check recentlyClicked
      e.stopPropagation();
      this.recentlyClicked = true;
      setTimeout(() => {
        this.recentlyClicked = false;
      }, 100);

      this.chooseSuggestion(parseInt(index, 10));
    });
  }

  disconnectedCallback(): void {
    document.addEventListener('click', (e) => {
      if (!this.contains(e.target as Node)) {
        // They clicked outside the menu, hide it
        this.hide();
        return;
      }
      const index = (e.target as HTMLElement).getAttribute('data-index');
      if (!index) return;
      e.stopPropagation();
      this.chooseSuggestion(parseInt(index, 10));
    });
  }

  selectNext(): void {
    this.selectedIndex = (this.selectedIndex + 1) % this.suggestions.length;
    this.render();
  }

  isShown(): boolean {
    return this.style.display !== 'none';
  }

  selectPrevious(): void {
    this.selectedIndex = (this.selectedIndex - 1 + this.suggestions.length) % this.suggestions.length;
    this.render();
  }

  hide(): void {
    if (this.style.display === 'none') return;
    this.style.display = 'none';
  }

  linkToNote = async (noteToLink: Note): Promise<void> => {
    this.note.links.push(noteToLink.id);
    noteToLink.backlinks.push(this.note.id);
    await getDB().updateNotes([this.note, noteToLink]);
  };

  chooseSuggestion = async (i: number): Promise<void> => {
    const startTime = performance.now();
    if (i < 0 || i >= this.suggestions.length) return;
    let noteToLink: Note | SearchNote = this.suggestions[i];
    if (i === 0) {
      // They're making a new one, check if the note with that title already exists
      // usually it'll be in the suggestions already
      const existingSuggestion = this.suggestions.find((n) => n.title === this.query);
      // otherwise check the database
      const existingNote = existingSuggestion || (await getDB().getNoteByTitle(this.query));
      // otherwise create a new note
      noteToLink = existingNote || createNote(this.query, 'takeaway', '', this.note.id);
    }
    if (isSearchNote(noteToLink)) {
      const dbNote = await getDB().getNoteById(noteToLink.id);
      if (dbNote) noteToLink = dbNote;
    }
    console.log('noteToLink - calling callback', noteToLink);
    // insert the link and get the new markdown value
    const newMarkdown = this.selectCallback(noteToLink);
    this.note.value = newMarkdown;

    // link and save the two notes
    this.linkToNote(noteToLink as Note);
    this.hide();
    console.log('chooseSuggestion took', performance.now() - startTime, 'ms');
  };

  chooseSelected(): void {
    this.chooseSuggestion(this.selectedIndex);
  }

  async updateQuery(
    query: string,
    note: Note,
    position: { top: number; left: number },
    trigger: string,
    selectCallback: (suggestion: Note | SearchNote) => string,
  ): Promise<void> {
    this.query = query;
    this.note = note;
    this.style.top = `${position.top + window.scrollY}px`;
    this.style.left = `${position.left + window.scrollX}px`;
    this.selectedIndex = -1;
    this.selectCallback = selectCallback;

    const endTrigger = trigger === '#' ? ' ' : ']]';
    const endTriggerIndex = this.query.indexOf(endTrigger);

    if (endTriggerIndex !== -1) {
      // The user has typed a new takeaway completely
      const newTitle = this.query.slice(0, endTriggerIndex);
      this.query = newTitle;
      this.chooseSuggestion(0);
      return;
    }

    // Fetch the suggestions based on the query
    const newTakeawaySuggestion = createNote(`+ New Note "${query}"`, 'takeaway');
    // Do a quick filter of the existing suggestions based on the updated query
    this.suggestions = [
      newTakeawaySuggestion,
      ...this.suggestions.slice(1).filter((s) => s.title.includes(query) || s.value.includes(query)),
    ];
    this.render(); // render immediately and fetch the rest of the suggestions
    const notes = await getDB().searchNotes(query, ['source', 'takeaway'], 8);
    this.suggestions = [newTakeawaySuggestion, ...notes.filter((n) => n.title.length > 0)];
    this.render();
  }

  render(): void {
    this.querySelector('#menu-container')!.innerHTML = `
          ${this.suggestions
            .map(
              (suggestion, index) =>
                `<div class="small-note ${suggestion.type} ${
                  index === this.selectedIndex ? 'suggestion-active' : ''
                }" data-index="${index}">
              ${suggestion.title.trim() || suggestion.value}
                  ${
                    suggestion.type === 'source'
                      ? `<div class='source-info-row'><img class='favicon' src="${getFaviconUrl(
                          suggestion.url || '',
                        )}" alt="favicon" /><span class='domain'>${getDomain(suggestion.url || '')}</span></div>`
                      : ''
                  }
                </div><div class='spacer'></div>`,
            )
            .join('')}
      `;
    this.style.display = this.suggestions.length > 0 ? 'block' : 'none';
  }
}

customElements.define(SUGGEST_MENU_TAG, SuggestMenu);

// One suggest menu for the whole page
export const suggestMenu = document.createElement(SUGGEST_MENU_TAG) as SuggestMenu;
document.body.appendChild(suggestMenu);
