// SearchBar functional component
import { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { Icon, IconOption } from './Icon';
import { TextInput } from './TextInput'; // DAVE NOTE 1/6/24 this very generic textinput is really overkill here.
// Let's just simply style the search input here.
// I'd also like to move away from controlled text inputs.  We should follow the style setup in takeaways.tsx

type SearchBarProps = {
  onSearch: (searchTerm: string) => void;
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)<{ isVisible: boolean }>`
  position: absolute;
  right: 6px;
  top: 10px;
  color: ${colors.icon.placeholder};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [value, setValue] = useState<string>('');
  const [isHovering, setIsHovering] = useState(false);

  const handleChange = (_id: string, newValue: string): void => {
    setValue(newValue);
    onSearch(newValue);
  };

  return (
    <Wrapper onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <TextInput id="searchbar" onChange={handleChange} outsideValue={value} iconOption={IconOption.Search} />
      <StyledIcon
        type={IconOption.Close}
        size="12px"
        isVisible={isHovering}
        onClick={() => {
          setValue('');
          onSearch('');
        }}
      />
    </Wrapper>
  );
};

export default SearchBar;
