// Handle the search functionality of exploring
// SearchContext.js
import React, { useState, createContext, ReactNode } from 'react';
import styled from 'styled-components';
import { TypeOfNote } from 'core/types';

const Highlight = styled.span`
  background: #f19b4a;
`;

export const highlightSearchTerm = (text: string, searchTerm: string): React.ReactNode => {
  if (!searchTerm) return text;

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return parts.map((part, i) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? <Highlight key={i}>{part}</Highlight> : part,
  );
};

export const highlightSearchTermsListWithContext = (
  text: string,
  searchTerm: string,
): { node: React.ReactNode; text: string }[] => {
  const results: { node: React.ReactNode; text: string }[] = [];
  const words = text.split(/\s+/);

  // Regular expression to find all occurrences of the searchTerm
  // const regex = new RegExp(`\\b${searchTerm}\\b`, 'gi');
  const regex = new RegExp(`${searchTerm}`, 'gi');
  const replacement = <Highlight>{searchTerm}</Highlight>;

  words.forEach((word, index) => {
    if (word.match(regex)) {
      const start = Math.max(index - 2, 0); // Ensuring start is not negative
      const end = Math.min(index + 3, words.length); // Ensuring end does not exceed array length
      const textBefore = words.slice(start, index).join(' ');
      const textAfter = words.slice(index + 1, end).join(' ');
      const charsBeforeInWord = word.substring(0, word.indexOf(searchTerm));
      const charsAfterInWord = word.substring(word.indexOf(searchTerm) + searchTerm.length);
      const context = (
        <>
          {textBefore} {charsBeforeInWord}
          {replacement}
          {charsAfterInWord} {textAfter}
        </>
      );

      // Replace the matched word with the div-wrapped version
      results.push({
        node: context,
        text: `${textBefore} ${charsBeforeInWord}${replacement}${charsAfterInWord} ${textAfter}`,
      });
    }
  });

  return results;
};

type SearchContextType = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  typeFilter: TypeOfNote | undefined;
  setTypeFilter: React.Dispatch<React.SetStateAction<TypeOfNote | undefined>>;
};

// Create the context with a default value
export const SearchContext = createContext<SearchContextType>({
  searchTerm: '',
  setSearchTerm: () => {},
  typeFilter: undefined,
  setTypeFilter: () => {},
});

type SearchProviderProps = {
  children: ReactNode;
};

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState<TypeOfNote | undefined>(undefined);
  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm, typeFilter, setTypeFilter }}>
      {children}
    </SearchContext.Provider>
  );
};
