import { AnySelector } from './selectorTypes';

export type TypeOfNote = 'takeaway' | 'source' | 'annote';
export const TYPES_OF_NOTES: TypeOfNote[] = ['takeaway', 'source', 'annote'];
export const NOTE_TAB_LABELS = { all: 'All', takeaway: 'Notes', source: 'Articles', annote: 'Highlights' };
export type UUID = string;

export type SourceDetails = {
  textContent: string;
  length: number;
  excerpt: string;
  byline: string;
  dir: string;
  siteName: string;
  lang: string;
  author: string | null;
  publishedTime: string | null;
  canonicalUrl?: string;
};

export type AnnoteDetails = {
  selectors: AnySelector[];
};

export type Position = { x: number; y: number };

// a briefer version of a note from search results
export type SearchNote = {
  id: UUID;
  title: string;
  value: string;
  type: TypeOfNote;
  url?: string;
};

export type Note = {
  id: UUID;
  userId?: UUID; // userId should be auto set by the server
  type: TypeOfNote;
  title: string;
  value: string;
  url?: string;
  createdFromId?: UUID;
  details?: SourceDetails | AnnoteDetails;
  createdAt: string;
  syncedAt?: string | null;
  deletedAt?: string | null; // null if not yet deleted
  links: UUID[];
  backlinks: UUID[];
};

export type SearchResults = Note[] | SearchNote[];

export type Profile = {
  userId: UUID;
  displayName: string;
  email: string;
  photoUrl?: string;
  createdAt: string;
  syncedAt: string;
  deletedAt?: string | null;
  blockedHosts: string[];
};

export type NoteMap = Record<UUID, Note>;

export type Annote = Note & {
  type: 'annote';
  createdFromId: string;
  details: AnnoteDetails;
};

export type Source = Note & {
  type: 'source';
  url: string;
  details: SourceDetails;
};

export type Takeaway = Note & {
  type: 'takeaway';
};

export type Database = {
  Profile: Profile;
  Notes: Note;
};

export type LinkKind = 'tree' | 'graph';

export type LinkInfo = {
  fromNote: Note;
  toNote: Note;
  kind: LinkKind;
};

export const isAnnote = (note: Note): note is Annote => {
  return note?.type === 'annote';
};

export const isSource = (note: Note): note is Source => {
  return note?.type === 'source';
};

export const isSearchNote = (note: Note | SearchNote): note is SearchNote => {
  // check for the createdAt field.  search notes don't have it
  return !('createdAt' in note);
};
