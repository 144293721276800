import { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Annote } from 'core/types';
import { TextQuoteSelector } from 'core/types/selectorTypes';
import { colors, text } from 'core/styles';
import { SearchContext, highlightSearchTerm } from 'src/context/SearchContext';
import { ProseEditor } from 'src/components/ProseEditor';

export const Wrapper = styled.div`
  gap: 10px;
  padding: 1rem 2.5rem;
  color: ${colors.text.primary};
  font-family: ${text.family.reading};
  font-weight: ${text.weight.regular};
  font-size: ${text.size.reading};
  font-style: normal;
  line-height: ${text.height.highlight};

  .ProseMirror {
    color: ${colors.text.primary};
    padding-left: 1rem;
    display: inline;
    font-family: ${text.family.handwriting};
    font-size: ${text.size.handwriting};
    line-height: inherit;
  }

  .ProseMirror p {
    cursor: text;
    outline: none;
    border: 0;
    display: inline;
    margin: 0;
    background: transparent;
  }
`;

export const NoteWrapper = styled.div`
  display: inline;
  background: ${colors.bg.note.primary};

  :hover {
    background: ${colors.bg.note.hover};
  }
  &:focus {
    background: ${colors.bg.note.focus};
  }
`;

export const Highlight = styled.span`
  background: ${colors.bg.quote.primary};
  height: inherit;
  margin: 0px;
  &:hover {
    background: ${colors.bg.quote.hover};
  }

  &:hover .ProseMirror {
    background: ${colors.bg.note.hover};
  }
  &.focused {
    background: ${colors.bg.quote.focus};
  }
  ::before {
    content: ' ';
    background: #fbfbfb;
  }
`;

export const AnnoteCard: FC<{ annote: Annote }> = ({ annote }) => {
  const { searchTerm } = useContext(SearchContext);

  const getBeforeAndAfterText = (annoteToCheck: Annote, len: number = 20): [string, string] => {
    const textSelector = annoteToCheck.details.selectors.find(
      (s) => s?.type === 'TextQuoteSelector',
    ) as TextQuoteSelector;
    const prefix = textSelector?.prefix ?? '';
    const before = prefix.substring(prefix.length - len, prefix.length);

    // TODO - make this seperate based on space and remove helpers/shortenText.ts
    const suffix = textSelector?.suffix ?? '';
    const after = suffix.substring(0, len);
    return [before, after];
  };

  const [beforeTextToShow, afterTextToShow] = useMemo(() => getBeforeAndAfterText(annote), [annote]);

  return (
    <Wrapper className="appAnnote">
      {highlightSearchTerm(beforeTextToShow, searchTerm)}
      <Highlight>{highlightSearchTerm(annote.title, searchTerm)}</Highlight>
      <NoteWrapper>
        <ProseEditor note={annote} />
      </NoteWrapper>
      {highlightSearchTerm(afterTextToShow, searchTerm)}
    </Wrapper>
  );
};

// TODO this should probably be handled with state
// Global small annote click handler (unlike focuscontext this is for annotes only)
let focusedAnnote: HTMLElement | null = null;
document.addEventListener('click', (e) => {
  const target = e.target as HTMLElement;
  if (focusedAnnote) {
    focusedAnnote.classList.remove('focused');
    focusedAnnote = null;
  }
  const annote = target.closest('.appAnnote') as HTMLDivElement;
  if (annote) {
    annote.classList.toggle('focused');
    if (annote.classList.contains('focused')) {
      focusedAnnote = annote;
      const proseMirror = annote.querySelector('.ProseMirror') as HTMLElement;
      if (proseMirror) proseMirror.focus();
    }
  }
});
