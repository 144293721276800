import React from 'react';
import styled from 'styled-components';

const ComingSoonWrapper = styled.div`
  display: flex;
  font-family: serif;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-size: 4rem;
  color: #333;
  background-color: #faf6dc;

  h1 {
    font-size: 6rem;
    margin: 0;
    display: flex;
  }
`;

const ComingSoon: React.FC = () => (
  <ComingSoonWrapper>
    <h1>Annote</h1>
    <p>Coming Soon</p>
  </ComingSoonWrapper>
);

export default ComingSoon;
