import { IDatabase } from '../../dbs/types';

let annoteDB: IDatabase;

// Setup the database and add the suggest menu to the document
export const initialize = (database: IDatabase): void => {
  annoteDB = database;
};

export const getDB = (): IDatabase => annoteDB;
