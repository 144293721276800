import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ShownIdsContext } from 'src/context/ShownContext';
import { colors, text as textStyles } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';
import { Note, UUID, isSource, isAnnote } from 'core/types';

const Wrapper = styled.div`
  padding: 1.375rem;
  cursor: pointer;
  border-radius: 6px;
  background: ${colors.bg.primary};
  box-shadow: ${colors.shadow.surfacenav};
  z-index: 10000;
  display: flex;
  align-items: center;
  font-size: ${textStyles.size.primary};
  font-family: ${textStyles.family.primary};
`;

const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

const ConfirmationMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.bg.note.primary};
  color: ${colors.text.description};
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none; // Prevent it from interfering with clicks
  margin-top: 8px; // Add some space between the button and the message

  &.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

// Returns text formated for AI from a list of notes
const notesToTextForAI = (shownIds: UUID[], surfaceNoteMap: Record<UUID, Note>): string => {
  const takeaways: Note[] = [];
  const annotes: Note[] = [];
  const sources: Note[] = [];
  const linkMap: Record<string, string[]> = {};

  const notes = shownIds.map((id) => surfaceNoteMap[id]);
  // TODO - get annotes - this below isn't working for some reason.
  // also add all notes created by a shown Note (this gets annotes context)
  Object.values(surfaceNoteMap).forEach((note) => {
    // console.log(
    //   'note scanning note for',
    //   note.id,
    //   note.title,
    //   note.createdFromId,
    //   shownIds.includes(note.createdFromId || ''),
    //   note.createdFromId && note.createdFromId in shownIds,
    // );
    if (note.type !== 'annote') return;
    if (shownIds.includes(note.id)) return;
    if (note.createdFromId && shownIds.includes(note.createdFromId)) {
      console.log('adding extra note', note.type, note.title);
      notes.push(note);
    }
  });

  // Categorize notes and build link map
  notes.forEach((note) => {
    if (isSource(note)) {
      sources.push(note);
    } else if (isAnnote(note)) {
      annotes.push(note);
    } else {
      takeaways.push(note);
    }
    linkMap[note.id] = note.links;
  });

  const miniId = (id: UUID): string => {
    return id.slice(0, 8);
  };

  const formatAnnote = (note: Note): string => {
    const sourceTitle = note.createdFromId ? surfaceNoteMap[note.createdFromId]?.title : 'Unknown';
    return `ANNOTE[${miniId(note.id)}]:
HIGHLIGHT: "${note.title}"
NOTE: "${note.value}"
SOURCE: "${sourceTitle}"
`;
  };

  const formatSource = (note: Note): string => {
    if (!isSource(note)) return '';
    return `SOURCE[${miniId(note.id)}]:
TITLE: "${note.title}"
AUTHOR: "${note.details.author}"
SITE: "${note.details.siteName}"
CONTENT: "${note.value}"
URL: ${note.url}
`;
  };

  const formatTakeaway = (note: Note): string => {
    return `TAKEAWAY[${miniId(note.id)}]:
TITLE: "${note.title}"
CONTENT: "${note.value}"
`;
  };

  const AITemplates = {
    annote: formatAnnote,
    source: formatSource,
    takeaway: formatTakeaway,
  };

  const note2AIText = (note: Note): string => {
    let text = AITemplates[note.type](note);

    const shownLinkIds = note.links.filter((id) => shownIds.includes(id) && surfaceNoteMap[id].type !== 'annote');
    if (shownLinkIds.length > 0) {
      const linkedIds = shownLinkIds.map((id) => miniId(id)).join(', ');
      text += `Links to: ${linkedIds}\n`;
    }
    return `${text}\n---\n`;
  };

  let result = 'BEGIN DOCUMENT: NOTES FROM ANNOTE.COM\n';

  if (takeaways.length > 0) {
    result += `\nTAKEAWAYS:\n\n${takeaways.map(note2AIText).join('\n')}`;
  }

  if (annotes.length > 0) {
    result += `\nANNOTATIONS:\n\n${annotes.map(note2AIText).join('\n')}`;
  }

  if (sources.length > 0) {
    result += `\nSOURCES:\n\n${sources.map(note2AIText).join('\n')}`;
  }

  result += '\nEND DOCUMENT';
  return result.trim();
};

const CopyNotes: React.FC = () => {
  const { shownIds, surfaceNoteMap } = useContext(ShownIdsContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCopyNotes = (): void => {
    const notesText = notesToTextForAI(shownIds, surfaceNoteMap);
    navigator.clipboard
      .writeText(notesText)
      .then(() => {
        // You can add a toast or snackbar here to show a success message
        console.log('Notes copied to clipboard');
        setShowConfirmation(true);
        setTimeout(() => setShowConfirmation(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy notes: ', err);
      });
  };

  return (
    <Wrapper onClick={handleCopyNotes}>
      <Icon type={IconOption.Copy} size="2.25rem" />
      <ButtonText>Copy Notes for AI</ButtonText>
      <ConfirmationMessage className={showConfirmation ? 'visible' : ''}>Copied to Clipboard</ConfirmationMessage>
    </Wrapper>
  );
};

export default CopyNotes;
